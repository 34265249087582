import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box } from '@mui/material';

// core components
import Navbar from "components/Navbars/Navbar.js";
import SubHeader from "components/Headers/SubHeaderBanner";
import DarkFooter from "components/Footers/DarkFooter.js";
import PanelCountdown from "components/PanelCountdown";
import events from './agenda.json'
import './style.scss';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {/* <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box> */}
                    <div className="index">
                        {children}
                    </div>
                </>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



function Agenda() {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    // const [basicActive, setBasicActive] = useState('tab1');

    // const handleBasicClick = (value) => {
    //     if (value === basicActive) {
    //         return;
    //     }

    //     setBasicActive(value);
    // };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        event.preventDefault()
    };


    return (
        <>
            <Navbar />
            <SubHeader title="CONFERENCE AGENDA" image={require("assets/img/danangbeach.png")} />
            <div className="wrapper position-relative mb-5">
                <div className="container main mt-5 px-lg-5">
                    <h4>
                    The FIBS Global Conference 2025 will be held in Da Nang, Vietnam. The 3.5-day event will consist of keynote speeches, network updates, Face-to-Face
                     Meetings, cocktail receptions, and after-conference evening events to give all delegates an unforgettable networking experience.
                    </h4>

                    <div className='container' style={{ marginBottom: '200px' }}>
                        <div className="col-12 text-left pt-3 d-none d-md-block desktop-table" >
                            <div className="bg-white shadow px-2 pt-2 pb-3">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <div className="table-title">
                                            <h3 className="title">FIBS Global Conference 18-21 February</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <h5>Tuesday, 18 February 2025</h5>
                                </div>
                                <table className="table table-bordered table-striped light-dark agenda-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Event</th>
                                            {/* <th scope="col">Venue</th> */}
                                            <th scope="col">Dress Code</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>06:20</td>
                                            <td><span className="font-weight-bold">Golfers </span>
                                            <br />Meet at hotel lobby for transport to golf course</td>
                                            {/* <td></td> */}
                                            <td>Golf Attire</td>

                                        </tr>
                                        <tr>
                                            <td>06:50 – 13:30</td>
                                            <td><span className="font-weight-bold">The FIBS Par-Tee</span>
                                            <br/>18-hole social golf game followed by lunch
                                            </td>
                                            {/* <td></td> */}
                                            <td>Golf Attire</td>

                                        </tr>
                                        <tr>
                                            <td>15:00 – onwards</td>
                                            <td><span className="font-weight-bold">Onsite Registration </span>
                                            <br />Pick up conference badge and materials near hotel lobby</td>
                                            {/* <td></td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>19:00  – 21:30 </td>
                                            <td className="font-weight-bold">FIBS Tropical Welcome Evening</td>
                                            {/* <td></td> */}
                                            <td>Resort Chic</td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-center mt-5'>
                                    <h5>Wednesday, 19 February 2025</h5>
                                </div>
                                <table className="table table-bordered table-striped light-dark agenda-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Event</th>
                                            {/* <th scope="col">Venue</th> */}
                                            <th scope="col">Dress Code</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>09:00  – 09:15 </td>
                                            <td className="font-weight-bold">Opening Remarks by Fredrik Nyberg</td>
                                            {/* <td>Conference Hall</td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>09:15 – 10:00 </td>
                                            <td><span  className="font-weight-bold">Speech </span><br/> Chen-Hsin Ma <br/>
                                            <span className='font-italic'>E-Freight Technology</span></td>
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>10:00  – 10:15 </td>
                                            <td className="font-weight-bold">Coffee Break</td>
                                            {/* <td></td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>10:15  – 11:00 </td>
                                            <td><span  className="font-weight-bold">Speech </span><br/> Kunal Maheshwari <br/>
                                            <span className='font-italic'>Softlink Global</span></td>
                                            {/* <td></td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>11:00  – 11:30 </td>
                                            <td className="font-weight-bold">Group Photo</td>
                                            {/* <td></td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>12:00  – 13:20 </td>
                                            <td className="font-weight-bold">Lunch</td>
                                            {/* <td>Mina’s Kitchen</td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>13:20  – 16:20 </td>
                                            <td className="font-weight-bold">Face-to-Face Meetings</td>
                                            {/* <td></td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>16:50</td>
                                            <td><span className="font-weight-bold">Meeting at hotel lobby </span> for transport to 
                                            <span className="font-weight-bold"> Hoi An</span> – UNESCO World Heritage Site </td>
                                            <td>FIBS Conference shirt</td>

                                        </tr>
                                        <tr>
                                            <td>17:20 – 22:00</td>
                                            {/* <td><span className="font-weight-bold">Cocktail and Dinner</span> <br/> Meet at hotel lobby for transport to Hoi An (UNESCO World Heritage Site)</td> */}
                                            <td><span className="font-weight-bold">An Evening of Charm: </span>Drinks, Dinner & Night Market in 
                                            <span className="font-weight-bold"> Hoi An</span></td>
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>22:00</td>
                                            <td className="font-weight-bold">Transport back to hotel</td>
                                            <td>-</td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-center mt-5'>
                                    <h5>Thursday, 20 February 2025</h5>
                                </div>
                                <table className="table table-bordered table-striped light-dark agenda-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Event</th>
                                            {/* <th scope="col">Venue</th> */}
                                            <th scope="col">Dress Code</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>10:00  – 10:30 </td>
                                            <td>
                                                <span  className="font-weight-bold">Speech </span> <br/>
                                             Roland M. Schaub <br/>
                                             <span className='font-italic'>Agile Beyond </span></td>
                                            {/* <td>Conference Hall</td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>10:30 – 10:45</td>
                                            <td className="font-weight-bold">Coffee Break</td>
                                            {/* <td></td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>10:45 – 11:15</td>
                                            <td><span className="font-weight-bold">Special Guest Storyteller </span><br/> 
                                            Bruce Aitken <br/>
                                            <span className='font-italic'>Author of Mr. Clean </span></td>
                                            {/* <td></td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>11:15 – 11:30</td>
                                            <td className="font-weight-bold">Coffee Break</td>
                                            {/* <td></td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>11:30 – 12:00</td>
                                            <td><span  className="font-weight-bold">Speech </span> 
                                             <br/>
                                             Joseph Carnarius <br/>
                                             <span className='font-italic'>FreightForce One </span></td>
                                            {/* <td></td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>12:00 – 13:20</td>
                                            <td className="font-weight-bold">Lunch</td>
                                            {/* <td>Mina’s Kitchen</td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>13:20  – 17:20 </td>
                                            <td className="font-weight-bold">Face-to-Face Meetings</td>
                                            {/* <td></td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>18:30</td>
                                            <td><span className="font-weight-bold">Meeting at hotel lobby </span>for transport to night event – 
                                            <span className="font-weight-bold"> Above the Ceiling: Under the Stars</span></td>
                                            {/* <td></td> */}
                                            <td>Twilight Chic</td>

                                        </tr>
                                        <tr>
                                            <td>22:00 </td>
                                            <td className="font-weight-bold">Transport back to hotel</td>
                                            {/* <td></td> */}
                                            <td></td>

                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-center mt-5'>
                                    <h5>Friday, 21 February 2025</h5>
                                </div>
                                <table className="table table-bordered table-striped light-dark agenda-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Time</th>
                                            <th scope="col">Event</th>
                                            {/* <th scope="col">Venue</th> */}
                                            <th scope="col">Dress Code</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>10:00 – 12:00 </td>
                                            <td className="font-weight-bold">Face-to-Face Meetings</td>
                                            {/* <td>Conference Hall</td> */}
                                            <td>Business Attire</td>

                                        </tr>
                                        <tr>
                                            <td>12:20  – 14:00 </td>
                                            <td className="font-weight-bold">Lunch</td>
                                            {/* <td></td> */}
                                            <td></td>

                                        </tr>
                                        <tr>
                                            <td>14:00  – 15:40 </td>
                                            <td className="font-weight-bold">Face-to-Face Meetings</td>
                                            {/* <td></td> */}
                                            <td>-</td>

                                        </tr>
                                        <tr>
                                            <td>15:40 - onwards</td>
                                            <td><span className="font-weight-bold">Tap into Good Times & Success: </span> <br/> 
                                             Conference Close / Happy Hour</td>
                                            {/* <td></td> */}
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='container container-padding d-md-none p-0'>
                            <div class="col-12 force-text-black px-1 pb-2">
                                <div class="card">
                                    <div class="card-body px-2 py-3">


                                        {/* {
                                            events.map((event) => (
                                                <>
                                                    
                                                    <table width="100%">
                                                        <tbody>
                                                            <tr>
                                                                <td valign="top" width="30%">Time</td>
                                                                <td valign="top">{event.from} - {event.to}</td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="top">Event</td>
                                                                <td valign="top"><div dangerouslySetInnerHTML={{ __html: event.event }}></div></td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="top">Venue</td>
                                                                <td valign="top">{event.vanue}</td>
                                                            </tr>
                                                            <tr>
                                                                <td valign="top">Dress Code</td>
                                                                <td valign="top">{event.dress}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </>
                                            ))
                                        } */}
                                        <div className="table-title">
                                            <h3 className="title text-center">FIBS Global Conference 18-21 February</h3>
                                        </div>
                                        <div className='text-center'>
                                            <h5>Tuesday, 18 February 2025</h5>
                                        </div>
                                        <table width="100%">
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td className="border-bottom" valign="top">06:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td className="border-bottom" valign="top"><strong>Golfers</strong><br />Meet at hotel lobby for transport to golf course</td>
                                                </tr>
 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">--</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top" className="border-bottom">Golf Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">06:50 – 13:30</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>The FIBS Par-Tee</strong><br/>18-hole social golf game followed by lunch</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top" >Golf Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">15:00 onwards</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>Onsite Registration</strong><br/>Pick up conference badge and materials near hotel lobby</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">19:00 – 21:30</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>FIBS Tropical Welcome Evening</strong>
                                                    <br/>Casual cocktail evening by the beach</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Resort Chic</td>
                                                </tr>
                                            </tbody>
                                        </table>


                                        <div className='text-center mt-5'>
                                            <h5>Wednesday, 19 February 2025</h5>
                                        </div>
                                        <table width="100%">
                                        <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">09:00 – 09:15</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>Opening Remarks by Fredrik Nyberg</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">09:15 – 10:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom">
                                                        <span  className="font-weight-bold">Speech </span><br/> Chen-Hsin Ma <br/>
                                                        <span className='font-italic'>E-Freight Technology</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>

                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">10:00 – 10:15</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom font-weight-bold">Coffee Break</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">10:15 – 11:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom">
                                                        <span  className="font-weight-bold">Speech </span><br/> Kunal Maheshwari <br/>
                                                        <span className='font-italic'>Softlink Global</span></td>
                                                </tr>
 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">--</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">11:00 – 11:30</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom font-weight-bold">Group Photo</td>
                                                </tr>
 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">--</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">12:00 – 13:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom font-weight-bold">Lunch</td>
                                                </tr>
                                                {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">Mina’s Kitchen</td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">13:20 – 16:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom font-weight-bold">Face-to-Face Meetings</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">16:50</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>Meeting at hotel lobby </strong>for transport to
                                                    <strong> Hoi An </strong>– UNESCO World Heritage Site</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">FIBS Conference shirt</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">17:20 – 22:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>An Evening of Charm:</strong> Drinks, Dinner & 
                                                    Night Market in <strong> Hoi An</strong></td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">22:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom font-weight-bold">Transport back to hotel</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className='text-center mt-5'>
                                        <h5>Thursday, 20 February 2025</h5>
                                        </div>
                                        <table width="100%">
                                        <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">10:00 – 10:30</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom">
                                                    <span  className="font-weight-bold">Speech </span> <br/>
                                                        Roland M. Schaub <br/>
                                                    <span className='font-italic'>Agile Beyond </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">10:30 – 10:45</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom font-weight-bold">Coffee Break</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">10:45 – 11:15</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom">
                                                    <span className="font-weight-bold">Special Guest Storyteller </span><br/> 
                                                    Bruce Aitken <br/>
                                                    <span className='font-italic'>Author of Mr. Clean </span>
                                                    </td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">11:15 – 11:30</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>Coffee Break</strong></td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">11:30 – 12:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom">
                                                    <span  className="font-weight-bold">Speech </span> 
                                                        <br/>
                                                        Joseph Carnarius <br/>
                                                        <span className='font-italic'>FreightForce One </span>
                                                    </td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">12:00 – 13:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom font-weight-bold">Lunch</td>
                                                </tr>
                                                {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">Mina’s Kitchen</td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top"></td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">13:20 – 17:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom font-weight-bold">Face-to-Face Meetings</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>

                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">18:30</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>Meeting at hotel lobby  </strong>
                                                        for transport to night event – <strong>Above the Ceiling: Under the Stars</strong></td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top" className="border-bottom">Twilight Chic</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">22:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>Transport back to hotel</strong></td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className='text-center mt-5'>
                                            <h5>Friday, 21 February 2025</h5>
                                        </div>
                                        <table width="100%">
                                        <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">10:00 – 12:20</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom font-weight-bold">Face-to-Face Meetings</td>
                                                </tr>
                                               {/*   <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">Conference Hall</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">Business Attire</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">12:20 – 14:00</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>Lunch</strong></td>
                                                </tr>
                                               {/*   <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top">Conference Hall</td>
                                                </tr>*/}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">14:00 – 15:40</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>Face-to-Face Meetings</strong></td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                            <tbody className='border-top'>
                                                <tr>
                                                    <td valign="top" width="30%">Time</td>
                                                    <td valign="top" className="border-bottom">15:40 onwards</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top">Event</td>
                                                    <td valign="top" className="border-bottom"><strong>Tap into Good Times & Success: </strong><br/>
                                                    Conference Closing Happy Hour</td>
                                                </tr>
                                                 {/* <tr>
                                                    <td valign="top">Venue</td>
                                                    <td valign="top"></td>
                                                </tr> */}
                                                <tr>
                                                    <td valign="top">Dress Code</td>
                                                    <td valign="top">-</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Day 1" {...a11yProps(0)} />
                                <Tab label="Day 2" {...a11yProps(1)} />
                                <Tab label="Day 3" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                            <div className='row schedule-item'>
                                <div className="col-md-2"><h4>11:35 AM</h4></div>
                                <div className="col-md-6">
                                    <h4>111</h4>
                                </div>
                                <div className="speaker-logo col-md-4">
                                    <h4>Lunch Break</h4>
                                </div>
                            </div>
                        </TabPanel>
                    </Box> */}

                </div>
                <PanelCountdown date="02/18/2025" />
                {/* <DarkFooter /> */}
            </div>
        </>
    );
}

export default Agenda;
